html body {
  height: auto;
  font-family:"C2RFont";
}

@font-face {
  font-family: C2RFont;
  src: url("PROXIMANOVA-REG-WEBFONT.TTF");
}

body {
  margin: 0;
  font-family:"C2RFont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: "C2RFont";
}
.paragraph{
  margin-left: 50px;
  margin-right: 50px;
  font-size: 20px;
  
}
.heading{
  margin-left: 50px;
  margin-right: 50px;
  font-size: 45px;
}

.work-area-heading {
  text-align: center;
  color: black;
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 30px;
}
