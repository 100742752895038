
  
  div.scroll-team {
    overflow: auto;
    white-space: nowrap;
  }

  div.scroll-team-card {
    display: inline-block;
    color: black;
    text-align: center;
    padding-right: 14px;
   
   
  }