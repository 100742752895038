.footer-body {
    min-height: 200px;
    background: #df721a;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background: url('/images/footer/1.webp');
}

.footer-column {
    width: 20%;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-column-title {
    font-size: medium;
    font-weight: 700;
    color: #333333;
}

.footer-column-title2 {
    font-size: 10px;
    text-align: center;
    font-weight: 700;
    color: #333333;
}


.footer-links {
    font-size: medium;
    font-weight: 500;
    color: #333333;
    margin: 4px 0;
}

.social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.social-media-item {
    box-shadow: 10px 10px 18px 0 rgba(0, 0, 0, .4);
    margin: 8px;
    border-radius: 50%;
}

.footerimg{
    margin-top: -10px;
}

.social-media-item:hover {
    transform: scale(1.2);
    transition: transform .1s ease-in-out;
}
@media(max-width:900px){
    .footer-column{
        width: 100%;
        align-items: center;
        margin-bottom: 34px;
    }
    .footer-body{
        flex-direction: column;
    }
    .footer-column-title{
        text-align: center;
    }
}