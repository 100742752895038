.banner {
  padding: 52px;
  text-align: justify;
  padding-left: 60px;
    padding-right: 60px;
    // text-align: center;
  color: #333333;
  // font-style: italic;
  // font-weight: 700;
  font-size: 20px;
  border-bottom: 1px solid #e7e7e7;
  
}

.banner2 {
  padding: 52px;
  height: 150px;
    text-align: center;
  color: #333333;
  // font-weight: 700;
  font-size: 24px;
  margin-bottom: 16px;

}
.image1{
  height: 50px;
  width: 67px;
}

@media(max-width:900px){
  .banner{
    font-size: 15px;
    padding-left:4% !important;
    padding-right:4% !important;
  }
  .banner2{
    font-size: 15px;
    padding-right: 4% !important;
    padding-left: 4% !important;
    margin-top: 6% !important;
  }
  .image1{
    height: 25px;
    width: 32px;
  }
}
