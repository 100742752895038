.page-container {
  min-height: 60vh;
  padding: 30px 50px 80px 50px;

}

.page-heading {
  text-align: center;
  color: black;
  font-size: 32px;
}


.page-paragraph {
  color:black;
  font-size:22px;
  margin-left:5%;
  margin-right:5%;
  text-align: justify;
  
}


.page-paragraph-il {
  color:black;
  font-size:20px;
  margin-left:7%;
  margin-right:5%;
  text-align: justify;
  
}

.page-paragraph-ans {
  color:black;
  font-size:20px;
  margin-left:5%;
  margin-right:5%;
  text-align: justify;
  
}
