.work-areas-container {
  width: auto;
  padding: 24px;
  border-bottom: 1px solid #e7e7e7; ;
}

.work-area-heading {
  text-align: center;
  color: black;
  font-weight: 900;
  font-size: 32px;
  margin-bottom: 30px;
}

.areas-container {
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.area-box {
  width: 580px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px;
  box-shadow: 10px 10px 18px 0 rgba(0, 0, 0, .4);
}

.area-box:hover {
  transform: scale(1.02);
  transition: transform .1s ease-in-out;
}



.education {
  background:linear-gradient(rgba(22, 22, 22, 0.5),rgba(22, 22, 22, 0.5)), url('/images/works/1.jpg');
  background-repeat: no-repeat;
  background-size: 660px 350px;
}

.health {
  background:linear-gradient(rgba(22, 22, 22, 0.5),rgba(22, 22, 22, 0.5)), url('/images/works/2.jpg');
  background-repeat: round;
}

.skill-development {
  background:linear-gradient(rgba(22, 22, 22, 0.5),rgba(22, 22, 22, 0.5)), url('/images/works/3.jpg');
  background-repeat: round;
}

.women-empowerment {
  background:linear-gradient(rgba(22, 22, 22, 0.5),rgba(22, 22, 22, 0.5)), url('/images/works/4.jpg');
  background-repeat: round;
}

.area-box-content {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.area-title {
  color: #fdfcfc;
  font-weight: 700;
  font-size: 24px;
}

.area-description {
  color: #fdfcfc;
  font-weight: 500;
  font-size: 16px;
}
@media(max-width:900px){
  .area-box{
    width: 350px;
  }
  
}

@media(max-width:600px)
{
  .area-box {
    margin-left:0 !important;
    margin-right: 0 !important;
   
  }
}