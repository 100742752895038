.team-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}


h1 {
    margin-top : 50px;
    font-size:55px;
    text-align:center;
}