.main-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
    padding: 8px 24px;
    position: fixed;
    top: 0;
     z-index: 10;
     background-color: white;
    // background: url('/images/footer/1.jpg');
    // background-size: cover;
    border: 1px solid #e7e7e7;;
}


.logo {
    width: 220px;
}


.title {
    font-size: xx-large;
    color: saddlebrown;
    margin-left: 8px;
    font-weight: bolder;
}

.logo-and-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

 @media(max-width: 950px){
    .logo{
        width: 140px;
    }
    .title{
        font-size: large;
    }
}