@import url(https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap);
html body{height:auto;font-family:"C2RFont"}@font-face{font-family:C2RFont;src:url(/static/media/PROXIMANOVA-REG-WEBFONT.198359ad.TTF)}body{margin:0;font-family:"C2RFont";-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;width:100%}code{font-family:"C2RFont"}.paragraph{margin-left:50px;margin-right:50px;font-size:20px}.heading{margin-left:50px;margin-right:50px;font-size:45px}.work-area-heading{text-align:center;color:black;font-weight:900;font-size:32px;margin-bottom:30px}

.page-container{min-height:60vh;padding:30px 50px 80px 50px}.page-heading{text-align:center;color:black;font-size:32px}.page-paragraph{color:black;font-size:22px;margin-left:5%;margin-right:5%;text-align:justify}.page-paragraph-il{color:black;font-size:20px;margin-left:7%;margin-right:5%;text-align:justify}.page-paragraph-ans{color:black;font-size:20px;margin-left:5%;margin-right:5%;text-align:justify}

.main-header{display:flex;flex-direction:row;justify-content:space-between;align-items:center;width:100%;height:100px;padding:8px 24px;position:fixed;top:0;z-index:10;background-color:white;border:1px solid #e7e7e7}.logo{width:220px}.title{font-size:xx-large;color:saddlebrown;margin-left:8px;font-weight:bolder}.logo-and-title{display:flex;flex-direction:row;align-items:center}@media (max-width: 950px){.logo{width:140px}.title{font-size:large}}

.ant-menu li{font-weight:900;font-size:18px}.barsMenu{float:right;height:60px;display:none;padding:0 !important;width:60px;border-radius:8px}.barsMenu img{width:60px;height:60px;margin:0 !important}.donte{background-color:#df721a;color:black;font-size:20px}.donte a :hover{background-color:#54e6e6}@media (max-width: 950px){.barsMenu{display:inherit}.horizontal-menu{display:none}}

.footer-body{min-height:200px;background:#df721a;padding:24px;display:flex;flex-direction:row;justify-content:flex-start;align-items:flex-start;background:url("/images/footer/1.webp")}.footer-column{width:20%;min-width:200px;display:flex;flex-direction:column;align-items:flex-start}.footer-column-title{font-size:medium;font-weight:700;color:#333333}.footer-column-title2{font-size:10px;text-align:center;font-weight:700;color:#333333}.footer-links{font-size:medium;font-weight:500;color:#333333;margin:4px 0}.social-media-container{display:flex;flex-direction:row;justify-content:flex-start}.social-media-item{box-shadow:10px 10px 18px 0 rgba(0,0,0,0.4);margin:8px;border-radius:50%}.footerimg{margin-top:-10px}.social-media-item:hover{transform:scale(1.2);transition:transform .1s ease-in-out}@media (max-width: 900px){.footer-column{width:100%;align-items:center;margin-bottom:34px}.footer-body{flex-direction:column}.footer-column-title{text-align:center}}

.content{margin-top:64px}

.ant-carousel .slick-slide{text-align:center;overflow:hidden;width:80%;height:100%}.ant-carousel .slick-slide img{width:100%;height:800px}.carousel-body{width:100%;padding-top:35px;margin-bottom:8px}@media (max-width: 900px){.slick-slide img{height:360px}.ant-carousel .slick-slide img{height:360px}}

.banner{padding:52px;text-align:justify;padding-left:60px;padding-right:60px;color:#333333;font-size:20px;border-bottom:1px solid #e7e7e7}.banner2{padding:52px;height:150px;text-align:center;color:#333333;font-size:24px;margin-bottom:16px}.image1{height:50px;width:67px}@media (max-width: 900px){.banner{font-size:15px;padding-left:4% !important;padding-right:4% !important}.banner2{font-size:15px;padding-right:4% !important;padding-left:4% !important;margin-top:6% !important}.image1{height:25px;width:32px}}

.work-areas-container{width:auto;padding:24px;border-bottom:1px solid #e7e7e7}.work-area-heading{text-align:center;color:black;font-weight:900;font-size:32px;margin-bottom:30px}.areas-container{width:100%;display:flex;flex-direction:row;flex-wrap:wrap;justify-content:center}.area-box{width:580px;height:350px;display:flex;flex-direction:column;align-items:center;justify-content:center;margin:16px;box-shadow:10px 10px 18px 0 rgba(0,0,0,0.4)}.area-box:hover{transform:scale(1.02);transition:transform .1s ease-in-out}.education{background:linear-gradient(rgba(22,22,22,0.5), rgba(22,22,22,0.5)),url("/images/works/1.jpg");background-repeat:no-repeat;background-size:660px 350px}.health{background:linear-gradient(rgba(22,22,22,0.5), rgba(22,22,22,0.5)),url("/images/works/2.jpg");background-repeat:round}.skill-development{background:linear-gradient(rgba(22,22,22,0.5), rgba(22,22,22,0.5)),url("/images/works/3.jpg");background-repeat:round}.women-empowerment{background:linear-gradient(rgba(22,22,22,0.5), rgba(22,22,22,0.5)),url("/images/works/4.jpg");background-repeat:round}.area-box-content{text-align:center;display:flex;flex-direction:column}.area-title{color:#fdfcfc;font-weight:700;font-size:24px}.area-description{color:#fdfcfc;font-weight:500;font-size:16px}@media (max-width: 900px){.area-box{width:350px}}@media (max-width: 600px){.area-box{margin-left:0 !important;margin-right:0 !important}}

div.scroll-team{overflow:auto;white-space:nowrap}div.scroll-team-card{display:inline-block;color:black;text-align:center;padding-right:14px}

.card{padding:16px;width:32%}.card-container{width:320px;height:400px;padding:15px;border-radius:8px;display:flex;flex-direction:column;align-items:center;box-shadow:10px 10px 18px 0 rgba(219,205,205,0.4);border:1px solid #e7e7e7}.card-container:hover{transform:scale(1.02);transition:transform .1s ease-in-out}.member-image{width:80%;height:56%;border-radius:120px}.member-name{margin-top:16px;font-size:20px;font-weight:700;color:black}.member-designation{margin-top:8px;font-size:15px;font-weight:700;color:black}.member-social{margin-top:8px;margin-bottom:16px}.member-social a{margin-left:16px;margin-right:16px}.member-social a:hover{opacity:0.7}


.team-group{width:100%;display:flex;flex-direction:row;justify-content:center;flex-wrap:wrap}h1{margin-top:50px;font-size:55px;text-align:center}






@media (max-width: 528px){.page-heading{font-size:35px !important}}

.working-model-Image-container{align-items:center}.working-model-Image{width:80%;max-width:100%;margin-left:10%;margin-right:auto;border-radius:10px;box-shadow:0 4px 8px 0 rgba(0,0,0,0.2);transition:0.3s;margin-bottom:32px}.working-model-Image:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2)}


.impactImage{padding-left:60px;height:400px;width:600px}


.page-heading{font-size:50px}.our-people-container{height:800px}.people-left{padding:10px;width:650px;max-width:90%;margin-left:25px;margin-top:40px;border:2px solid black;border-radius:10px;box-shadow:0 4px 8px 0 rgba(0,0,0,0.4);transition:0.3s}.people-left:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.4)}.people-right{padding:10px;width:650px;max-width:90%;margin-right:20px;margin-top:40px;margin-bottom:10px;border:2px solid black;border-radius:10px;float:right;box-shadow:0 4px 8px 0 rgba(0,0,0,0.4);transition:0.3s}.people-right:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.4)}.teamtext{font-size:30px}span.a{display:inline}.people-img{height:170px;width:170px;margin-right:20px;margin-bottom:20px;vertical-align:middle;float:left;border-radius:50px}.people-img2{height:170px;width:170px;margin-left:20px;margin-bottom:20px;vertical-align:middle;float:right;border-radius:50px}.team{display:inline;font-size:20px;text-align:justify}

.team-member-flex{display:flex;margin-top:20px;margin-left:500px;width:75%}.team-member-flex img{width:25%}.team-member-data{display:block;width:100%;padding:0.75rem;text-align:center;font-family:noto-sans,sans-serif}.team-member-data h1{margin:0 !important;font-size:2.75rem;color:black;font-weight:600;line-height:1.125}.team-member-data p{color:#1e88e5;letter-spacing:1.5px;text-transform:uppercase;margin:0;padding-top:0.5rem;line-height:28px;font-size:1.5em;font-weight:400}.team-member-data div{padding-top:1.5rem}


.page-container{padding-left:0 !important;padding-right:0 !important}

.page-container{padding-left:0 !important;padding-right:0 !important}






.contact-us{margin-top:100px}

