@import '~antd/dist/antd.css';

.ant-menu li {
  font-weight:900;
  font-size:18px;
}


.barsMenu{
    float: right;
    height: 60px;
    display: none;
    padding:0 !important;
    width:60px;
    border-radius: 8px;
  }

  .barsMenu img {
    width:60px;
    height:60px;
    margin:0 !important;
  
  }

 

  .donte{ 
    background-color: #df721a;  
    color: black;
    font-size: 20px;
    a :hover{
        background-color: #54e6e6;
    }
}

  @media(max-width: 950px){
    .barsMenu{
      display: inherit;
    }
    .horizontal-menu{
      display: none;
      
    }
   
  
  }