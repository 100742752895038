.card {
    padding: 16px;
    width:32%;

}

.card-container {
    width: 320px;
    height: 400px;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 10px 10px 18px 0 rgba(219, 205, 205, 0.4);
    
    border: 1px solid #e7e7e7;
}

.card-container:hover {
    transform: scale(1.02);
    transition: transform .1s ease-in-out;
}

.member-image {
    width: 80%;
    height: 56%;
    border-radius: 120px;
    
}

.member-name {
    margin-top: 16px;
    font-size: 20px;
    font-weight: 700;
    color: black;
}

.member-designation {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 700;
    color: black;
}

.member-social {
    margin-top:8px;
    margin-bottom:16px;
}

.member-social a {
    margin-left:16px;
    margin-right:16px;
}

.member-social a:hover {
    opacity: 0.7;
}
