.ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
    width: 80%;
    height:100%;
}


.ant-carousel .slick-slide img {
    width: 100%;
    height: 800px;
}

.carousel-body {
    width: 100%;
    padding-top: 35px;
    margin-bottom: 8px;
}
@media(max-width:900px){
    .slick-slide img{
        height: 360px;
    }
    .ant-carousel .slick-slide img{
        height: 360px;
    }
}
